@import "~react-image-gallery/styles/css/image-gallery.css";
@import "./normalize.css";

html {
  scroll-behavior: smooth;
  font-size: calc(13px + 0.25vw);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Quicksand', sans-serif;
/*  text-transform: uppercase;
*/  background-color: #222222;
  color: white;

}

a {
  color: white;
}

p {
  margintop: 10;
}

h1 {
  letter-spacing: 5px;

}

#helloView {
  letter-spacing: 5px;
  font-size: calc(13px + 0.4vw);
}


#contactView {
  background-color: #222222;
  color: white;
  padding-top: 30px;
}

#gallery,
.image-gallery,
.image-gallery-content,
.image-gallery-slide-wrapper {
  height: 100vh;
}

#gallery img {
  height: 100vh;
  object-fit: cover;
}

#gallery-container {
  height: 100vh;
  position: relative;
  text-align: center;
}
